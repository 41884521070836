import icons from './../shared/icons';
import moment from 'moment';
import i18n from './../../i18n';
import HelpersMaps from './../helpers/helpersMap';
import { uuid } from 'vue-uuid';
import axios from 'axios';

export default {
  instance : JSON.parse(window.localStorage.getItem('instance')),
  icons: icons,
  type: {
    'entrave': i18n.t('mapPage.conflictEntrave'),
    'closing': i18n.t('mapPage.conflictClosing'),
    'detour': i18n.t('mapPage.conflictDetour')
  },
  drawPolyline(path, Polyline, Graphic, type, color) {
    let p = new Polyline({
      paths : path.geometry.paths,
      spatialReference : path.geometry.spatialReference,
      hasM : false,
      hasZ : false
    });
    let typeLine = this.typeLine(type);
    let widthLine = this.widthLine(type);
    let graph = new Graphic({
      geometry   : p,
      attributes : path.attributes,
      visible : true,
      symbol : {
        type: 'simple-line',
        style: typeLine,
        color: color,
        width: widthLine
      },
    });
    return graph;
  },
  pinLineConflict(element, symbol, popupTemplate, Point, Polyline, Graphic, GeometryEngine, WebMercatorUtils) {
    let midPoint = HelpersMaps.polylineMidPoint(element, Point, Polyline, GeometryEngine, WebMercatorUtils);
    let graph = new Graphic({
      geometry   : midPoint,
      popupTemplate: popupTemplate,
      visible    : true,
      symbol: symbol
    });
    return graph;
  },
  pinPointConflict(element, symbol, popupTemplate, Point, Polyline, Graphic) {
    let point = element.selected.path[0];
    point.geometry = new Point({
      x : element.selected.path[0].geometry.x,
      y : element.selected.path[0].geometry.y,
      spatialReference : element.selected.path[0].geometry.spatialReference,
      hasM : element.selected.path[0].geometry.hasM,
      hasZ : element.selected.path[0].geometry.hasZ
    });
    let graph = new Graphic({
      geometry   : point.geometry,
      attributes : point.attributes,
      popupTemplate: popupTemplate,
      visible    : true,
      symbol: symbol
    });
    return graph;
  },
  drawConflict(project, conflicts, Point, Polyline, Graphic, GeometryEngine, WebMercatorUtils) {
    let graphs={'phases': [], 'detours': []};
    project.entraves.entraves.forEach((entrave) => {
      let symbol=this.conflictMarker('entrave');
      if (conflicts[entrave.entraveId]) {
        let popupTemplate = this.populateConflictPopup(conflicts[entrave.entraveId]);
        if (entrave.type=='ponctuel') {
          let graph = this.pinPointConflict(entrave, symbol, popupTemplate, Point, Polyline, Graphic);
          graphs.phases.push(graph);
        } else {
          let graph = this.pinLineConflict(entrave, symbol, popupTemplate, Point, Polyline, Graphic, GeometryEngine, WebMercatorUtils);
          graphs.phases.push(graph);
        }
      }
    });
    project.phases.forEach((phase) => {
      phase.closing.forEach((close) => {
        let symbol=this.conflictMarker('closing');
        if (conflicts[close.closingId]) {
          let popupTemplate = this.populateConflictPopup(conflicts[close.closingId]);
          if (close.type=='ponctuel') {
            let graph = this.pinPointConflict(close, symbol, popupTemplate, Point, Polyline, Graphic);
            graphs.phases.push(graph);
          } else {
            let graph = this.pinLineConflict(close, symbol, popupTemplate, Point, Polyline, Graphic, GeometryEngine, WebMercatorUtils);
            graphs.phases.push(graph);
          }
        }
        close.detours.forEach((detour) => {
          if (conflicts[detour.detourId]) {
            let popupTemplate = this.populateConflictPopup(conflicts[detour.detourId]);
            let symbol=this.conflictMarker('detour');
            if (conflicts[detour.detourId] && (conflicts[detour.detourId])) {
              let graph = this.pinLineConflict(detour, symbol, popupTemplate, Point, Polyline, Graphic, GeometryEngine, WebMercatorUtils);
              graphs.detours.push(graph);
            } 
          }
        });
      });
    });
    return graphs;
  },
  drawPin(project, Point, Polyline, Graphic, GeometryEngine, WebMercatorUtils, color, fromPublic, FilterValue) {
    let publish = project.informations.publish;
    let entraves=project.entraves.entraves;
    let selected=entraves[0];
    for (var i=0; i <entraves.length; i++) {
      if ((entraves[i].type=='ponctuel') && (selected.type!='ponctuel')) {
        selected = entraves[i];
      }
    }
    var graph;
    let symbol=this.pinMarker(publish, selected.type, color);
    let popupTemplate = this.populatePopup(project, fromPublic, FilterValue);
    //let popupTemplate = this.populatePopup(project.uuid, project.informations.name, project.entraves.date, project.informations.notes, {fromPublic: fromPublic, publishReport: project.informations.publishReport, archives: project.archives, reportLink: reportLink, lang: project.lang});
    if (selected.type=='linear') {
      let midPoint = HelpersMaps.polylineMidPoint(selected, Point, Polyline, GeometryEngine, WebMercatorUtils);
      graph = new Graphic({
        geometry   : midPoint,
        popupTemplate: popupTemplate,
        visible    : true,
        symbol: symbol
      });
    } else {
      let point = selected.selected.path[0];
      point.geometry = new Point({
        x : selected.selected.path[0].geometry.x,
        y : selected.selected.path[0].geometry.y,
        spatialReference : selected.selected.path[0].geometry.spatialReference,
        hasM : selected.selected.path[0].geometry.hasM,
        hasZ : selected.selected.path[0].geometry.hasZ
      });
      graph = new Graphic({
        geometry   : point.geometry,
        // attributes : point.attributes,
        popupTemplate: popupTemplate,
        visible    : true,
        symbol: symbol
      });
    }
    return graph;
  },
  drawPoint(pointSrc, Graphic, type, color, Point, TypeClosing) {
    let point = pointSrc;
    point.geometry = new Point({
      latitude : pointSrc.geometry.latitude,
      longitue : pointSrc.geometry.longitue,
      x : pointSrc.geometry.x,
      y : pointSrc.geometry.y,
      spatialReference : pointSrc.geometry.spatialReference,
      hasM : pointSrc.geometry.hasM,
      hasZ : pointSrc.geometry.hasZ
    });
    let symbole;
    if (type=='closing') {
      symbole = this.pointClosingTypeMarker(TypeClosing, color);
    } else {
      symbole = this.pointMarker(type, color);
    }
    let graph = new Graphic({
      geometry   : point.geometry,
      attributes : point.attributes,
      visible    : true,
      symbol: symbole
    });
    return graph;
  },
  pointMarker(type,color) {
    let marker = {
      type: "simple-marker",
      style: "path",
      path: type=='closing'?this.icons.closePonctuel:this.icons.ponctuel,
      xoffset: 0,
      yoffset: 0,
      color: color
    };
    return marker;
  },
  pointClosingTypeMarker(type,color) {
    let marker = {
      type: "simple-marker",
      style: "path",
      path: this.icons[type],
      xoffset: 0,
      yoffset: 0,
      size: 14,
      color: color
    };
    return marker;
  },
  fetchEntityIcon(entity,city){
    if(city == "mississauga"){
      switch (parseInt(entity)) {
        case 1:
          return "static/img/icons/new/cone.svg";
        case 2: 
          return "static/img/icons/new/cone.svg";
        case 4:
          return "static/img/icons/new/cone.svg";
        case 8:
          return "static/img/icons/new/cone.svg";
        case 3:
          return "static/img/icons/new/setting.svg";
        case 6:
          return "static/img/icons/new/event.svg";
        case 7:
          return "static/img/icons/new/emergency.svg";
        case 10:
          return "static/img/icons/new/rail.svg";
        default:
          return "static/img/icons/new/cone.svg";
      }
    }else if( city == "brossard"){
      switch (parseInt(entity)) {
        case 1:
          return "static/img/icons/brossard/brossard.png";
        case 2: 
          return "static/img/icons/brossard/rem.png";
        case 3:
          return "static/img/icons/brossard/btp.png";
        case 4:
          return "static/img/icons/brossard/event.png";
        default:
          return "static/img/icons/brossard/brossard.png";
      }
    }
  },
  fetchEntityIconColor(entity){
    switch (parseInt(entity)) {
      case 1:
        return '#FF8C00';
      case 2:
        return '#ffff00';
      case 4:
        return '#b87332';
      case 8:
        return '#AA91E4';
      case 3:
        return '#1C02DE';
      case 6:
        return '#FFD700';
      case 7:
        return '#DC143C';
      case 10:
        return '#32CD32';
      default:
        return '#FF8C00';
    }
  },
  pinMarker(publish, type, color,entity) {
    let path;
    let marker;
    if(this.instance.city == "mississauga"){
      path = this.fetchEntityIcon(entity,this.instance.city);
      marker = {
        type: "picture-marker",
        url: path,
        width: "30px",
        height: "34px"
      };
    }else if(this.instance.city == "brossard"){
      path = this.fetchEntityIcon(entity,this.instance.city);
      marker = {
        type: "picture-marker",
        url: path,
      };
    }
    else{
      path = publish?(type==='linear'?this.icons.publishLineair:this.icons.publishPonctuel):(type==='linear'?this.icons.draftLineair:this.icons.draftPonctuel);
      marker = {
        type: "simple-marker",
        style: "path",
        path: path,
        size: 24,
        color: color
      };
    }    
    marker.yoffset = "20px";
    return marker;
  },
  conflictMarker(type) {
    let url;
    if (type=='entrave') {
      url='static/img/conflict-entrave.png';
    } else if (type=='closing') {
      url='static/img/conflict-closing.png';
    } else {
      url='static/img/conflict-detour.png';
    }
    var marker = {
      type: 'picture-marker',
      url: url,
      width: '30px',
      height: '30px'
    };
    marker.xoffset = "15px";
    marker.yoffset = "0px";
    return marker;
  },
  typeLine(type) {
    let typeLine;
    if ((type=='entrave') || (type=='create')) {
      typeLine='solid';
    } else if (type=='closing') {
      typeLine='short-dot';
    } else {
      typeLine='dash-dot';
    }
    return typeLine;
  },
  widthLine(type) {
    let widthLine;
    if ((type=='entrave') || (type=='create')) {
      widthLine='2px';
    } else if (type=='closing') {
      widthLine='3px';
    } else {
      widthLine='5px';
    }
    return widthLine;
  },
  //project.uuid, project.informations.name, project.entraves.date, project.informations.notes, {fromPublic: fromPublic, publishReport: project.informations.publishReport, archives: project.archives, reportLink: reportLink, lang: project.lang}
  //populatePopup(uuid, name, date, notes, details) {


  populatePopup(project, fromPublic, FilterValue) {
    let sdate, edate, obj, showDocuments, actions=[];
    let uuid = project.uuid, name = project.informations.name, date = project.entraves.date, notes = project.informations.notes;
    let details = {fromPublic: fromPublic, publishReport: project.informations.publishReport, archives: project.archives, lang: project.lang};
    
    let reportLink = window.location.protocol + '//'+ window.location.host + this.reportLink(project, project.informations.district, project.informations.workType);

    sdate = this.formatDate(date[0], details.fromPublic);
    edate = this.formatDate(date[1], details.fromPublic);

    let content = '<div class="popup-content"> <b>Date:</b> ' + sdate + ' - ' + edate + '</br>';
    if (notes) content += '<div class="public-notes"><b> Notes: &nbsp;</b>' + notes + '</div>';

    if (details.fromPublic) {
      actions = [{title:'Animation', id: 'public-project-info', uuid: uuid, name: name, className : "esri-icon-forward"}];
      if (details.publishReport) {
        if (project.city!='gatineau') {
          //reportLink+='&phase=all';
          content += '<div class="report-section"><a  href="' + reportLink + '" >' + i18n.t('publicPage.reportLink') + '</a></div>';
        } else  {
          //console.log(reportLink);
          reportLink = reportLink.replace('phase=all', '');
          content += '<div class="report-section">';
          project.phases.forEach(function(phase, phaseIndex) {
            if (phase.closing.length > 0 && phaseIndex===0) content +='<br> <strong>'+i18n.t('publicPage.noticeTitle')+'</strong>';
            phase.closing.forEach(function(close, closeIndex) {
              if (close.publish) {
                reportLink +='&phase=' + phase.phaseId + '&close=' + close.closingId;
                content +='<div style="margin-top: 10px"><a  href="' + reportLink + '"> ' + i18n.t('publicPage.phase') + (phaseIndex + 1) + ' ' + i18n.t('publicPage.closing') + (closeIndex + 1) + '</a></div>';
              }
            });
          });
          content += '</div>';
        }
      } 
      if (details.archives != null && details.archives.length > 0) {
        const isAnyArchivePublished = details.archives.find(archive => archive.published);
        if (isAnyArchivePublished) {
          content += '<div class="documents-section">';
          let title = '<b> Archive </b> <br>';
          content += title;
          details.archives.forEach(function(e) {
            if(e.published){
              let filePath='/archive/download?uuid=' + e.uuid;
              let link = window.location.protocol + '//' + window.location.host + filePath;
              let a = '<strong><a target="_blank"  style="margin-top: 10px" href="' + link + '">' + e.name +  '</a><strong> <br>';
              content += a;
            }
          });
          content += '</div>';
        }
      }
    } else {
      obj = {id: 'project-info', uuid: uuid, name: name, className : "esri-icon-description"};
      actions = [obj];
    }
    content += '</div>';
    let popupTemplate = {
      title: name,
      content: content,
      actions: actions
    };
    return popupTemplate;
  },
  reportLink(project, district, workType) {
    let params = {
      'uuid': project.uuid,
      'district': district,
      'workType': workType,
      'hour': false,
      'includeMap': true,
      'typeMap': 'gray',
      'includeAgent': false,
      'modeMap': '0',
      'phase': 'all',
      'lang': project.lang,
      'ruuid': uuid.v1(),
      'notice': '2',
      'zoom':15
    };
    let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return "/report/preview?"+queryString;
  },
  populateConflictPopup(conflict) {
    let name = conflict.project.name;
    let content = this.conflictContent(conflict);
    let popupTemplate = {
      title: name,
      content: content
    };
    return popupTemplate;
  },
  conflictContent(conflict) {
    let content='';
    if (conflict.project.step=='entrave') {
      content+='<span class="conflict-title">' + i18n.t('mapPage.conflictEntrave') + ': </span>';
    } else if (conflict.project.step=='closing') {
      content+='<span class="conflict-title">' +  i18n.t('mapPage.conflictClosing') + ': </span>';
    } else {
      content+='<span class="conflict-title">' +  i18n.t('mapPage.conflictDetour') + ': </span>';
    }
    content+=conflict.project.value + '<br/>' + this.constructTableConflict(conflict.inConflict);
    return content;
  },
  constructTableConflict(inConflicts) {
    let _this=this;
    let table='<br/> <span class="conflict-title">' +  i18n.t('mapPage.inConflict') + ': </span>';
    if (inConflicts.length > 0) {
      table+='<div class="esri-feature__fields esri-feature__content-element"> <table class="esri-widget__table" summary="Liste des attributs et des valeurs"><tbody>';
      inConflicts.forEach((element) => {
        table+='<tr>';
        table+='<th class="esri-feature__field-header">' + element.name + ' (' + _this.type[element.step] + ')</th>';
        table+='<th class="esri-feature__field-data">'   + element.value + '</th>';
        table+='<th class="esri-feature__field-data">'   + _this.constructDate(element.dates) + '</th>';
        table+='</tr>';
      });
      table+='</tbody></table></div>';
    }
    return table;
  },
  constructDate(dates) {
    let content='';
    let _this=this;
    if (dates.length > 0) {
      if (typeof dates[0] =='object') {
        dates.forEach((date) => {
          if (date[0]) {
            let sdate = date[0].ranges[0];
            let edate = date[0].ranges[1];
            content+=_this.formatDate(sdate, true) + ' - ' + _this.formatDate(edate, true) + '</br>';
          } else {
            let sdate = date.sdate;
            let edate = date.edate;
            content+=_this.formatDate(sdate, true) + ' - ' + _this.formatDate(edate, true) + '</br>';
          }
        });
      } else {
        content+=_this.formatDate(dates[0], true) + ' - ' + _this.formatDate(dates[1], true) + '</br>';
      }
    }
    return content;
  },
  formatDate(date, fromPublic) {
    if (fromPublic) {
      return moment(date).format('DD-MM-YYYY');
    } else {
      return moment(date).format('DD-MM-YYYY hh:mm:ss');
    }
  }
};