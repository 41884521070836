<template>
  <div class="map-page-container">
    <filters-component v-bind:hover='hover' :display='display' :filters='filters' :filtersConflict='filtersConflict' v-on:changeDisplaying="changeDisplaying($event)" v-on:changeFilters="changeFilters($event)" v-on:changeConflicts="changeConflicts($event)"></filters-component>
    <rs-panes split-to="rows" :allow-resize="true" primary="second" :size="timelineSize" v-on:update:size="timelineSize = $event" >
      <div slot="firstPane">
        <rs-panes :allowResize="true" split-to="columns" slot="firstPane" primary="second" :size="projectsDetails.width">
          <div slot="firstPane" id="map" class="full-screen-map" @mouseover="hover = true"  @mouseleave="hover = false">
            <help-public v-bind:lang="user.lang" v-bind:instance="instance.city" v-bind:enabledHelp="enabledHelp" ></help-public>
            <div v-show="loading" class="loading-map">
              <img src="../../../public/img/loading.gif" />
            </div>
            <div class="create-project">
              <base-button  v-if="isAdmin || isUser || isUserRes" @click="$router.push('/projects/create')" type="primary" class="btn btn-icon">
                <i class="tim-icons icon-simple-add"></i>
              </base-button>
              <base-button  v-if="(!!features.quickCreateProject && features.quickCreateProject.show) && (isAdmin || isUser || isUserRes)" @click="$router.push('/projects/quick/create')" type="info" class="btn btn-icon ml-2">
                <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" height="16px"  fill="#fff" ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"></path> </g></svg>
              </base-button>
            </div>
            <div v-if="(!!features.showGpsPositionButton && features.showGpsPositionButton.show)" class="get-geo-location">
              <base-button  v-if="isAdmin || isUser || isUserRes" @click="getGeoLocation()" type="primary" class="btn btn-icon btn-success">
                <i class="tim-icons icon-square-pin"></i>
              </base-button>
            </div> 
            <div id="legend-container"></div>
            <div class="projects-filters-container">
              <filters-projects v-bind:hover='hover' v-on:changeRanges="changeRanges($event)" v-on:changeTags="changeTags($event)" v-on:applyChangeTags="applyChangeTags($event)"></filters-projects>
            </div>
            <div class="projects-network-container">
              <network-component :flow='flow' v-bind:instance="instance" v-on:flowSelect="flowSelect($event)" v-on:legendEnable="legendEnable($event)" v-on:drawBusLines="drawBusLines($event)" ></network-component>
            </div>
          </div>
          <div slot="secondPane" class="projects-details-container">
            <projects-details v-bind:instance="instance" v-bind:projects="projectsDetails.selectedProjects" v-bind:display="display"  v-on:check="check($event)" v-on:uncheck="uncheck($event)" v-on:close="close($event)" ></projects-details>
          </div>
        </rs-panes>
      </div>
      <div class="timeline-container" slot="secondPane">
        <button v-show='data.length > 0' @click="openTimeline" class="settings-icon" ><i class="tim-icons icon-chart-pie-36"></i></button>
        <projects-timeline v-bind:projects="data" ></projects-timeline>
      </div>
      <!--
      <div class="timeline-container" slot="secondPane">
        <projects-timeline v-bind:projects="data" ></projects-timeline>
      </div>
      -->
    </rs-panes>
    <!--<projects-timeline v-bind:projects="data" ></projects-timeline>-->
  </div>
</template>
<script>
import axios from 'axios';
import { loadModules } from 'esri-loader';

import Vue from 'vue';
import ResSplitPane from 'vue-resize-split-pane';
Vue.component('rs-panes', ResSplitPane);

import FiltersComponent from './TopFiltersComponent.vue';
import NetworkComponent from './NetworkLayerComponent.vue';
import FiltersProjects from  './filtersProjectsComponent.vue';
import ProjectsDetails from  './projectsDetailsComponent.vue';
import ProjectsTimeline from  './TimelineProjectsComponent.vue';

import icons from './../shared/icons';
import colors from './../shared/colors';
import RoadNetwork from './../shared/roadNetwork';
import Tags from './../shared/filters';
import HelpPublic from './../../public/HelpPublic';

import HelpersMaps from './../helpers/helpersMap';
import HelpersDrawProject from './../helpers/helpersDrawProject';

import { MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns";
import { MultiSelect, CheckBoxSelection } from '@syncfusion/ej2-dropdowns';

import moment from 'moment';
import Features from '../shared/features';

MultiSelect.Inject(CheckBoxSelection);
Vue.use(MultiSelectPlugin);

//import { FlareClusterLayer }  from '../FlareClusterLayer';
const options = {
  // tell Dojo where to load other packages
  dojoConfig: {
    async: true,
    packages: [
      {
        location: location.pathname.replace(/\/[^/]+$/, '') + "static/fcl",
        name: 'fcl'
      }
    ],
    has: {
        "esri-promise-compatibility": 1 // enable native promises and remove the warning about using when() instead of then(). https://developers.arcgis.com/javascript/latest/guide/release-notes/index.html#improved-compatibility-with-javascript-promises
    }
  }
};
const maxSlices = 20;

export default {
  name: 'starter-page',
  components: {
    ResSplitPane,
    FiltersComponent,
    NetworkComponent,
    FiltersProjects,
    ProjectsDetails,
    ProjectsTimeline,
    HelpPublic
  },
  data() {
    return {
      enabledHelp: true,
      modalOptions: {
        modal: false,
        color: '#59c7f9',
        ncolor: '#59c7f9',
        puuid:null,
        suckerCanvas: null,
        suckerArea: [],
        isSucking: false
      },
      projectsDetails: {
        width: 0,
        selectedProjects: []
      },
      animRef: [],
      graphics: {},
      icons: icons,
      hover: false,
      display: {
        entraves: true,
        closing: false,
        detours: false,
        pin: true 
      },
      filters: {
        publish: true,
        nopublish: true,
        linear: true,
        ponctuel: true,
        ranges: [],
        tags: {}
      },
      filtersConflict: {
        sites: false,
        detours: false,
        sitesDetours: false
      },
      flow : {
        road: false,
        cycling: false
      },
      loading: false,
      user: null,
      instance: null,
      map: null,
      mapZoom: 20,
      view: null,
      layerStreets: null,
      entravesLayer: null,
      closingLayer: null,
      detoursLayer: null,
      pinLayer: null,
      selectionLayer: null,
      conflictsPhasesLayer: null,
      conflictsDetourLayer: null,
      geometryEngine: null,
      webMercatorUtils: null,
      geodesicUtils: null,
      GraphicsLayer: null,
      Graphic  : null,
      Polyline : null,
      Point: null,

      ClassBreaksRenderer : null,
      SimpleMarkerSymbol : null,
      SimpleLineSymbol : null,
      SimpleFillSymbol : null,
      TextSymbol : null,
      spatialReference : null,
      
      watchUtils : null,
      dynamicLayers: [],
      layerBus: null,
      extraLayerDraw: null,
      fcl : null,
      clusterLayer : null,
  
      drawColors: {
        drawing : '#1d8cf8',
        selected: '#1f8ef1'
      },
      firstLoad: true,
      focusUUID: null,
      focusProject: null,
      focusGraph: [],
      focusLayer: null,
      data: [],
      projects: {},
      projectsPins : [],
      conflicts: {},
      colors: colors.list,
      clusterScaleThreshold : 20000,
      removeTags: false,
      timelineSize : 0,
      state: '',
      layer: 'roadworks',
      features : []
    };
  },
  computed: {
    isAdmin() {
      return (window.localStorage.getItem('role')==='admin' || window.localStorage.getItem('role')==='adminGis');
    },
    isUser() {
      return window.localStorage.getItem('role')==='user';
    },
    isUserRes() {
      return window.localStorage.getItem('role')==='userRes';
    }
  },
  created() {
    if (this.$route.query && this.$route.query.uuid) {
      this.focusUUID = this.$route.query.uuid;
    } 
    this.user     = JSON.parse(window.localStorage.getItem('user'));
    this.instance = JSON.parse(window.localStorage.getItem('instance'));
    this.state = window.localStorage.getItem('state') || "";
    this.features = Features[this.instance.city];
  },
  mounted() {
    this.loadMap();
  },
  watch: {
    $route(to) {
      this.openTimeline();
      this.focusUUID = to.query.uuid;
      this.dispatchData();
    },
    'display.entraves'() {
      this.entravesLayer.visible = this.display.entraves;
    },
    'display.closing'() {
      this.closingLayer.visible = this.display.closing;
    },
    'display.detours'() {
      this.detoursLayer.visible = this.display.detours;
    },
    'display.pin'() {
      this.pinLayer.visible = this.display.pin;
    },
    'filters.publish'() {
      this.getProjects();
    },
    'filters.nopublish'() {
      this.getProjects();
    },
    'filters.linear'() {
      this.getProjects();
    },
    'filters.ponctuel'() {
      this.getProjects();
    },
    'filtersConflict.sites'() {
      if (this.filtersConflict.sites || this.filtersConflict.sitesDetours) {
        this.conflictsPhasesLayer.visible=true;
      } else {
        this.conflictsPhasesLayer.visible=false;
      }
      if ((!this.filtersConflict.sites) && (!this.filtersConflict.detours)) {
        this.filtersConflict.sitesDetours=false;
        this.conflictsPhasesLayer.visible=false;
        this.conflictsDetourLayer.visible=false;
      }
    },
    'filtersConflict.detours'() {
      if (this.filtersConflict.detours || this.filtersConflict.sitesDetours) {
        this.conflictsDetourLayer.visible=true;
      } else {
        this.conflictsDetourLayer.visible=false;
      }
      if ((!this.filtersConflict.sites) && (!this.filtersConflict.detours)) {
        this.filtersConflict.sitesDetours=false;
        this.conflictsPhasesLayer.visible=false;
        this.conflictsDetourLayer.visible=false;
      }
    },
    'filtersConflict.sitesDetours'() {
      if (this.filtersConflict.sitesDetours) {
        this.filtersConflict.sites=true;
        this.filtersConflict.detours=true;
      } else {
        this.filtersConflict.sites=false;
        this.filtersConflict.detours=false;
      }
    }
  },
  methods: {
    loadMap(){
      loadModules(["esri/Map",
      "esri/Basemap",
      "esri/views/MapView",
      "esri/geometry/projection",
      "esri/layers/FeatureLayer",
      "esri/layers/GraphicsLayer",
      "esri/Graphic",
      "esri/geometry/Polyline",
      "esri/geometry/Point",
      "esri/geometry/geometryEngine",
      "esri/geometry/support/webMercatorUtils",
      "esri/geometry/support/geodesicUtils",
      "esri/widgets/Search",
      //FlareClusterLayer Library
      "esri/symbols/SimpleMarkerSymbol",
      "esri/symbols/SimpleLineSymbol",
      "esri/symbols/SimpleFillSymbol",
      "esri/symbols/TextSymbol",
      "esri/renderers/ClassBreaksRenderer",
      "esri/geometry/SpatialReference",
      "static/fcl/FlareClusterLayer_v4.js",
      "esri/core/watchUtils",
      "esri/widgets/Popup",
      "esri/popup/content/AttachmentsContent",
      "esri/widgets/Legend",
      "esri/layers/VectorTileLayer",
      "esri/tasks/Locator"
      ], options).then(([Map, BaseMap, MapView, Projection, FeatureLayer, GraphicsLayer, Graphic, Polyline, Point, geometryEngine, webMercatorUtils, geodesicUtils, Search,
                         SimpleMarkerSymbol, SimpleLineSymbol, SimpleFillSymbol, TextSymbol, ClassBreaksRenderer, SpatialReference, fcl, watchUtils,Popup,AttachmentsContent,Legend,VectorTileLayer,Locator]) => {
        
        let location = [this.user.location.longitude, this.user.location.latitude];
        var basemap;
        //let type = (this.user.appsettings && this.user.appsettings.map && this.user.appsettings.map.typeMap) || 'osm';
        if (this.user.appsettings.map && this.user.appsettings.map.modeMap && this.user.appsettings.map.modeMap==="1") {
          basemap = new BaseMap({
            portalItem: {id: this.user.appsettings.map.typeMap}
          });
        }else if (this.user.appsettings.map && this.user.appsettings.map.modeMap && this.user.appsettings.map.modeMap==="2"){
          basemap = new BaseMap({
            baseLayers: [
              new VectorTileLayer({
                portalItem: {
                  id: this.user.appsettings.map.typeMap
                }
              })
            ]
          });
        } 
        else {
          basemap = (this.user.appsettings.map && this.user.appsettings.map.typeMap) || 'osm'; 
        }
        this.map = new Map({
          basemap: basemap
        });
        
        this.view = new MapView({
          container: "map",
          map: this.map,
          center: location, // longitude, latitude
          zoom: this.mapZoom,
          spatialReference: 102100,
          highlightOptions: {
            color: [255, 255, 255, 0],
            haloOpacity: 0,
            fillOpacity: 0
          },
        });
        this.view.ui.move("zoom", "bottom-right");
        this.layerStreets = new FeatureLayer({
          url : (RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].url:((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].url:RoadNetwork[this.instance.city].url),
          visible : false,
          //outFields: ["ID", "TYPE", "GENERIQUE", "LIAISON", "SPECIFIQUE", "DIRECTION", "NOM_TOPOGRAPHIE", "COTE", "ORIENTATION"] //IGNORED: SEGMENT,
          outFields: (RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].fields : ((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].fields:RoadNetwork[this.instance.city].fields)
        });
        
        this.Graphic = Graphic;
        this.Polyline = Polyline;
        this.Point = Point;
        this.Projection=Projection;
        this.webMercatorUtils=webMercatorUtils;
        this.geodesicUtils=geodesicUtils;
        this.geometryEngine = geometryEngine;
        this.ClassBreaksRenderer = ClassBreaksRenderer;
        this.SimpleMarkerSymbol = SimpleMarkerSymbol;
        this.SimpleLineSymbol = SimpleLineSymbol;
        this.SimpleFillSymbol = SimpleFillSymbol;
        this.TextSymbol = TextSymbol;
        this.spatialReference = SpatialReference;
        this.fcl = fcl;
        
        this.watchUtils = watchUtils;
        
        this.entravesLayer    = new GraphicsLayer();
        this.closingLayer     = new GraphicsLayer();
        this.detoursLayer     = new GraphicsLayer();
        this.pinLayer         = new GraphicsLayer();
        this.focusLayer       = new GraphicsLayer();
        this.selectionLayer   = new GraphicsLayer();
        this.extraLayerDraw   = new GraphicsLayer();
        
        this.conflictsPhasesLayer  = new GraphicsLayer();
        this.conflictsDetourLayer  = new GraphicsLayer();
        
        this.entravesLayer.visible = this.display.entraves;
        this.closingLayer.visible = this.display.closing;
        this.detoursLayer.visible = this.display.detours;
        this.pinLayer.visible = this.display.pin;
        this.focusLayer.visible = true;

        this.conflictsPhasesLayer.visible = this.filtersConflict.sites;
        this.conflictsDetourLayer.visible = this.filtersConflict.detours;
        
        this.map.add(this.entravesLayer);
        this.map.add(this.closingLayer);
        this.map.add(this.detoursLayer);
        this.map.add(this.pinLayer);
        this.map.add(this.focusLayer);
        this.map.add(this.selectionLayer);
        this.map.add(this.extraLayerDraw);
        
        this.map.add(this.conflictsPhasesLayer);
        this.map.add(this.conflictsDetourLayer);
        
        let _this=this;
        this.view.popup = true;
        this.view.popup.dockOptions = {
          position : "bottom-right"
        };
        this.view.popup.on("trigger-action", function(evt){
          /*
          if (evt.action.id=='zoom-to-feature') {
            this.view.goTo({
              zoom: this.view.zoom + 2
            });
            return;
          }
          */
          if (evt.action && evt.action.id=='project-info' && evt.action.uuid) {
            if (!_this.isSelectedProject(evt.action.uuid)) {
              _this.selectedProject(evt.action.uuid); 
            }
          }
        });
        let attachmentsElement = new AttachmentsContent({
          displayType: "preview" // this will show all attachments as a list of linked files
        })

        const popupTrailheads = {
          "title": "{label_name}",
          content: [attachmentsElement]
        }
        let legendLayer = []
        let dynamicLayersKeys = Object.keys(RoadNetwork[this.instance.city].dynamicLayers);
        dynamicLayersKeys.forEach((key) => {
          if (RoadNetwork[_this.instance.city].dynamicLayers[key]) {
            let nameLayer = new FeatureLayer({
              url: RoadNetwork[_this.instance.city].dynamicLayers[key].url,
              visible : false,
              // legendEnabled : RoadNetwork[_this.instance.city].dynamicLayers[key].isLegend,
            });
            if(RoadNetwork[_this.instance.city].dynamicLayers[key].isLegend === true){
              legendLayer.push({layer: nameLayer})
            }
            if(RoadNetwork[_this.instance.city].dynamicLayers[key].isTransparent != false && RoadNetwork[_this.instance.city].dynamicLayers[key].isTransparent != undefined){
              nameLayer.opacity = RoadNetwork[_this.instance.city].dynamicLayers[key].isTransparent;
            }
            if(this.instance.city==="can" || this.instance.city==="us" || this.instance.city==="montroyal"){
                this.view.popup.defaultPopupTemplateEnabled = true;
                // nameLayer.defaultPopupTemplateEnabled = true;
                // nameLayer.popupTemplate = popupTrailheads;
                // nameLayer.visible = true;
            }  
            _this.dynamicLayers.push({'key': key, 'layer': nameLayer});
            _this.map.add(nameLayer);
          }  
        });
        this.legend = new Legend({
          view: this.view,
          // layerInfos: legendLayer
        });
        // this.view.ui.add(legend);
        // var legendContainer = document.getElementById("legend-container");
        // legendContainer.appendChild(legend.domNode);
      
        axios.get('layer/list').then(response => {
        let data = response.data;
        if (data.success) {
          let res =  data.data;
          res.forEach((layer,key) =>{
            let nameLayer = new FeatureLayer({
                url: layer.url,
                visible : false,
              });
              _this.dynamicLayers.push({'key': key, 'layer': nameLayer});
              _this.map.add(nameLayer);
            
          })

          return true;
        } 
        }).catch((e) => {
          console.log("error",e)
          _this.$notify({
            message: e.message,
            timeout:   10000,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          });
        });

        if (RoadNetwork[this.instance.city].bus) {
          this.layerBus = new FeatureLayer({
            url: RoadNetwork[this.instance.city].bus.url,
            visible : false
          });  
        }
        this.view.when(()=>{
          this.addSearch(Search,Locator);
        })
        if (this.firstLoad) {
          this.getProjects();
        }
        this.viewArmZoomEvent();
    }).catch(err => {
      console.error(err);
    });
    },
    close(params) {
      let pUUID = params[0];
      let proGraphics = this.graphics[pUUID];
      let keys = Object.keys(proGraphics);
      let project = this.projects[pUUID];
      let _this=this;
      keys.forEach((key) => {
        let element = proGraphics[key];
        clearInterval(element.ref);
        _this.selectionLayer.removeMany(element.graphic);
        if (element.entry=='entrave') {
          project.entraves.entraves.forEach((entrave) => {
            if (entrave.entraveId==element.elementId) {
              if (entrave.type=='linear') {
                _this.drawEntravePolyline(entrave, element, 'entrave', element.color);
              } else {
                _this.drawPoint(entrave.selected.path[0], element, 'entrave', element.color);
              }
            }
          });
        } else if (element.entry=='closing') {
          project.phases.forEach((phase) => {
            phase.closing.forEach((close) => {
              if (close.closingId==element.elementId) {
                if (close.type=='linear') {
                  _this.selectionLayer.remove(element.pinCloseGraph);
                  _this.drawClosingPolyline(close, element, 'closing', element.color);
                } else {
                  _this.drawPoint(close.selected.path[0], element, 'closing', element.color);
                }
              }
            });
          });
        } else if (element.entry=='detour') {
          project.phases.forEach((phase) => {
            phase.closing.forEach((close) => {
              close.detours.forEach((detour) => {
                if (detour.detourId==element.elementId) {
                  _this.drawDetourPolyline(detour, element, 'detour', element.color); 
                }
              });
            });
          });
        }
      });
      this.projectsDetails.selectedProjects.forEach((pr, index) => {
        if (pr.uuid==pUUID) {
          _this.projectsDetails.selectedProjects.splice(index, 1);
        }
      });
      if (this.projectsDetails.selectedProjects.length===0) {
        this.projectsDetails.width=0; 
      }
    },
    selectedProject(uuid) {
      this.projectsDetails.selectedProjects.push(this.projects[uuid]);
      let width = window.innerWidth*35/100;
      if (this.projectsDetails.width===0) { this.projectsDetails.width=width;}
      this.animateGraphics(uuid);
    },
    isSelectedProject(uuid) {
      let isSelected=false;
      this.projectsDetails.selectedProjects.forEach((pr) => {
        if (pr.uuid==uuid) {
          isSelected=true;
        }
      });
      return isSelected;
    },
    animateGraphics(uuid) {
      let _this=this;
      let proGraphics = this.graphics[uuid];
      let keys = Object.keys(proGraphics);
      keys.forEach((key) => {
        let element = proGraphics[key];
        if (element.entry=='entrave') {
          _this.entravesLayer.removeMany(element.graphic);
          if (_this.display.entraves) {
            _this.drawAnimateEntrave(uuid, key, element);
          }
        } else if (element.entry=='closing') {
          _this.closingLayer.removeMany(element.graphic);
          _this.closingLayer.remove(element.pinCloseGraph);
          if (_this.display.closing) {
            _this.drawAnimateClosing(uuid, key, element);
          }
        } else if (element.entry=='detour') {
          _this.detoursLayer.removeMany(element.graphic);
          if (_this.display.detours) {
            _this.drawAnimateDetour(uuid, key, element);
          }
        }
      });
    },
    getRepeatedSymbole(selected, type, color, options) {
      let symbol = {
        type: "simple-marker",
        style : "path",
        path : (type=='detour')?this.icons.arrowDetour:this.icons.pointClosing,
        color: color,
        size : 5,
        outline: {
          color: color,
          width: 1
        }
      };
      let pg, i, slices, percent, graphs = [];
      
      let polyline = HelpersMaps.updateSimpleLine(selected, this.Point, this.Polyline, this.geometryEngine);
      let fullD = this.geometryEngine.geodesicLength(polyline, options.units);
      
      if(fullD > (2 * options.distance)){
        slices = Math.floor(fullD / options.distance);
        if(options.boundMax && (slices >= maxSlices)){slices = maxSlices;}
      } else{slices = 2;}
      percent = 100 / (slices * options.steps);
      //("Slices ", slices, "\nPercent ", percent);
      let points = HelpersMaps.repeatedPoints(polyline, this.Point, this.geometryEngine, this.webMercatorUtils, {percent : percent, units : options.units});
      let angles = HelpersMaps.bearingAt(polyline, points, this.Polyline, this.geometryEngine, this.webMercatorUtils, this.geodesicUtils);
      for (i=0; i < angles.length; i++){
        let a = angles[i];
        symbol.angle = a.angle;
        pg = new this.Graphic({
          geometry : a.point,
          symbol : symbol
        });
        graphs.push(pg);
      }
      return {polyline : polyline, graphs: graphs, slices: slices};
    },
    drawAnimateDetour(pUUID, detourId, element) {
      let _this=this;
      let project = this.projects[pUUID];
      let step = 0, i;
      let options = {
        steps: 10,
        distance: 50,
        units: 'meters',
        boundMax: true
      };
      var ref, angle, graphCache = {};
      project.phases.forEach((phase) => {
        phase.closing.forEach((close) => {
          close.detours.forEach((detour)  => {
            if ((detour.detourId==detourId) && (element.type=='linear')) {
              //element.color[3] = 1;
              let obj = this.getRepeatedSymbole(detour, 'detour', element.color, options);
              let visible = [];
              ref = setInterval(() => {
                try {
                  _this.selectionLayer.removeMany(visible);
                  if (!graphCache[step]){
                    graphCache[step] = [];
                    for (i = step; i < obj.slices * options.steps; i += options.steps){
                      //angle = HelpersMaps.bearingAt(obj.polyline, obj.graphs[i].geometry, this.Polyline, this.geometryEngine, this.webMercatorUtils, this.geodesicUtils);
                      //obj.graphs[i].symbol.angle = angle;
                      //_this.selectionLayer.add(obj.graphs[i]);
                      //visible.push(obj.graphs[i]);
                      graphCache[step].push(obj.graphs[i]);
                    }
                  }
                  visible = graphCache[step];
                  _this.selectionLayer.addMany(visible);
                  step = (step + 1) % options.steps;
                  
                  element.graphic = obj.graphs;
                 _this.graphics[pUUID][detourId] = element;
                } catch(e) {
                  clearInterval(ref);
                }
              }, 80);
              element.ref=ref;
            }
          });
        });
      });
    },
    drawAnimateClosing(pUUID, closingId, element) {
      let _this=this;
      let project = this.projects[pUUID];
      let options = {
        steps: 10,
        distance: 50,
        units: 'meters'
      };
      let step = 0, i;
      var baseOpacity = 0.1, steps1 = 20, step1 = 0, inc =  1, opacity;
      var color = element.color;
      var graph, ref;
      project.phases.forEach((phase) => {
        phase.closing.forEach((close) => {
          if (close.closingId==closingId) {
            if (element.type=='linear') {
              //element.color[3] = 1;
              _this.selectionLayer.add(element.pinCloseGraph);
              let obj = this.getRepeatedSymbole(close, 'closing', element.color, options);
              // reove animate
              let visible = [];
              _this.selectionLayer.removeMany(visible);
              for (i = step; i < obj.slices * options.steps; i += options.steps){
                _this.selectionLayer.add(obj.graphs[i]);
                visible.push(obj.graphs[i]);
              }
              element.graphic = obj.graphs;
              _this.graphics[pUUID][closingId] = element;
              // fin remove animate closing
              /*
              let visible = [];
              ref = setInterval(() => {
                try {
                  _this.selectionLayer.removeMany(visible);
                  for (i = step; i < obj.slices * options.steps; i += options.steps){
                    _this.selectionLayer.add(obj.graphs[i]);
                    visible.push(obj.graphs[i]);
                  }
                  step = (step + 1) % options.steps;
                  element.graphic = obj.graphs;
                 _this.graphics[pUUID][closingId] = element;
                } catch(e) {
                  clearInterval(ref);
                }
              }, 80);
              element.ref=ref;
              */
            } else {
              graph = this.getGraphPonctuelClosing(element.type, close, color);
              // reove animate
              _this.selectionLayer.add(graph);
              element.graphic = [graph];
              _this.graphics[pUUID][closingId] = element;
              // fin remove animate closing
              /*
              _this.selectionLayer.add(graph);
              ref = setInterval(() => {
                try {
                  opacity = baseOpacity + (1 - baseOpacity) * Math.sin((Math.PI * step1) / steps1);
                  color[3] = opacity;
                  _this.selectionLayer.remove(graph);
                  graph = _this.getGraphPonctuelClosing(element.type, close, color);
                  _this.selectionLayer.add(graph);
                  step1 = (step1 + inc) % (steps1 + 1);
                  element.graphic = [graph];
                  _this.graphics[pUUID][closingId] = element;
                } catch(e) {
                  clearInterval(ref);
                }
              }, 40);
              element.ref=ref;
              */
            }
          }
        });
      });
    },
    getGraphPonctuelClosing(type, close, color) {
      let graph;
      let ponctuelSymbol = {
        type: "simple-marker",
        style: "path",
        path: this.icons.closePonctuel,
        size: '18px',
        color: color
      };
      let point = close.selected.path[0];
      graph = new this.Graphic({
         geometry   : point.geometry,
         attributes : point.attributes,
         visible    : true,
         symbol: ponctuelSymbol
       });
      return graph;
    },
    getGraphEntrave(type, entrave, color) {
      let graph;
      let linearSymbol = {
        type  : "simple-line",
        cap   : "round",
        join  : "round",
        width : "2px",
        color: color
      };
      let ponctuelSymbol = {
        type: "simple-marker",
        style: "path",
        path: this.icons.ponctuel,
        size: '18px',
        color: color
      };
      if (type=='linear') {
        let polyline = HelpersMaps.updateSimpleLine(entrave, this.Point, this.Polyline, this.geometryEngine);
        graph = new this.Graphic({
          geometry   : polyline,
          visible : true,
          symbol : linearSymbol
        });
      } else {
        let point = entrave.selected.path[0];
        graph = new this.Graphic({
          geometry   : point.geometry,
          attributes : point.attributes,
          visible    : true,
          symbol: ponctuelSymbol
        });
      }
      return graph;
    },
    drawAnimateEntrave(pUUID, entraveId, element) {
      let _this = this;
      var baseOpacity = 0.1, opacity;
      var steps = 20, step = 0, inc = 1;
      let graph, ref;
      let color = element.color;
      let project = this.projects[pUUID];
      project.entraves.entraves.forEach((entrave)  => {
        if (entrave.entraveId==entraveId) {
          graph = this.getGraphEntrave(element.type, entrave, color);
          this.selectionLayer.add(graph);
          ref = setInterval(() => {
            try {
              opacity = baseOpacity + (1 - baseOpacity) * Math.sin((Math.PI * step) / steps);
              color[3] = opacity;
              _this.selectionLayer.remove(graph);
              graph = _this.getGraphEntrave(element.type, entrave, color);
              _this.selectionLayer.add(graph);
              element.graphic = [graph];
              _this.graphics[pUUID][entraveId] = element;
              step = (step + inc) % (steps + 1);
            } catch(e) {
              clearInterval(ref);
            }
          }, 40);
          element.ref=ref;
        }
      }); 
    },
    uncheck(params) {
      let pUUID = params[0];
      let type = params[1][0];
      let elementUUID = params[1][1];
      let project = this.projects[pUUID];
      let proGraphics = this.graphics[pUUID];
      let element = proGraphics[elementUUID];
      let _this=this;
      if (type=='entrave') {
        project.entraves.entraves.forEach((entrave) => {
          if (entrave.entraveId==elementUUID) {
            _this.selectionLayer.removeMany(element.graphic);
            clearInterval(element.ref);
          }
        });
      } else if (type=='closing') {
        project.phases.forEach((phase) => {
          phase.closing.forEach((close) => {
            if (close.closingId==elementUUID) {
              _this.selectionLayer.removeMany(element.graphic);
              _this.selectionLayer.remove(element.pinCloseGraph);
              clearInterval(element.ref);
            }
          });
        });
      } else if (type=='detour') {
        project.phases.forEach((phase) => {
          phase.closing.forEach((close) => {
            close.detours.forEach((detour) => {
              if (detour.detourId==elementUUID) {
                _this.selectionLayer.removeMany(element.graphic);
                clearInterval(element.ref);
              }
            });
          });
        });
      }
    },
    drawNewPin(pUUID, nColor) {
      this.pinLayer.removeAll();
      let _this=this;
      var color;
      let keys = Object.keys(this.projects);
      keys.forEach(function(uuid, index) {
        //color = (pUUID==uuid?nColor:_this.projects[uuid].color);
        if (pUUID==uuid) {
          color=nColor.substring(0, nColor.length-2);;
          _this.projects[uuid].color=color;
        } else {
          color=_this.projects[uuid].color;
        }
        let graph = HelpersDrawProject.drawPin(_this.projects[uuid], _this.Point, _this.Polyline, _this.Graphic, _this.geometryEngine, _this.webMercatorUtils, color,false,Tags[this.instance.city]);
        let geographic = _this.webMercatorUtils.webMercatorToGeographic(graph.geometry);
        let converted = turf.point([geographic.longitude, geographic.latitude]);
        _this.pinLayer.add(graph);
      });
    },
    check(params) {
      let pUUID = params[0];
      let type = params[1][0];
      let elementUUID = params[1][1];
      let project = this.projects[pUUID];
      let proGraphics = this.graphics[pUUID];
      let element = proGraphics[elementUUID];
      let selectedColor = params[1][2];
      element.color = HelpersMaps.hexToRgb(selectedColor);
      let _this=this;
      if (type=='entrave') {
        project.entraves.entraves.forEach((entrave) => {
          if (entrave.entraveId==elementUUID) {
            _this.drawAnimateEntrave(pUUID, entrave.entraveId, element);
            _this.drawNewPin(pUUID, selectedColor);
          }
        });
      } else if (type=='closing') {
        project.phases.forEach((phase) => {
          phase.closing.forEach((close) => {
            if (close.closingId==elementUUID) {
              _this.drawAnimateClosing(pUUID, close.closingId, element);
              if (close.type=='linear') {
                _this.selectionLayer.remove(element.pinCloseGraph);
                let graph = _this.drawPinLinearClose(close, element.color);
                _this.selectionLayer.add(graph);
              }
            }
          });
        });
      } else if (type=='detour') {
        project.phases.forEach((phase) => {
          phase.closing.forEach((close) => {
            close.detours.forEach((detour) => {
              if (detour.detourId==elementUUID) {
                _this.drawAnimateDetour(pUUID, detour.detourId, element);
              }
            });
          });
        });
      }
    },
    changeDisplaying(params) {
      this.display[params[0]]=params[1];
    },
    changeRanges(params) {
      this.filters.ranges=params;
      this.getProjects();
    },
    changeFilters(params) {
      this.filters[params[0]]=params[1];
    },
    changeConflicts(params) {
      this.conflicts[params[0]]=params[1];
    },
    changeTags(params) {
      let _this=this;
      _this.filters.tags={};
      params.forEach(function(element) {
        let entry = element.split('.');
        if (_this.filters.tags[entry[0]]) {
          _this.filters.tags[entry[0]].push(entry[1]);
        } else {
          _this.filters.tags[entry[0]] = [entry[1]];
        }
      });
      if (this.removeTags) {
        this.getProjects();
      }
    },
    flowSelect(params){
      this.loading=true;
      this.dynamicLayers.forEach((e) => {
        // console.log(e.layer.legendEnabled)
        if (e.key==params[0].key) {
          e.layer.visible=params[0].value
          // if(params[0].value && e.layer.legendEnabled){
          //   this.view.ui.add(this.legend);
          //   var legendContainer = document.getElementById("legend-container");
          //   legendContainer.appendChild(this.legend.domNode);
          // }else if(params[0].value == false){
          //   this.view.ui.remove(this.legend);
          // }
        }
      });
      this.loading=false;
    },
    legendEnable(params){
      if(params){
        this.view.ui.add(this.legend);
        var legendContainer = document.getElementById("legend-container");
        legendContainer.appendChild(this.legend.domNode);
      }else{
        this.view.ui.remove(this.legend);
      }
    },
    applyChangeTags(params) {
      if (params && params[0]) {
        this.removeTags=true;
      } else {
        this.getProjects();
      }
    },
    getProjects() {
      this.removeTags=false;
      let _this = this;
      this.loading=true;
      if (!_this.filters.tags.step) {
        _this.filters.tags.step=[''];
        Tags[_this.instance.city].step.value.forEach(element => {
          if((element.value!='7') && (element.value!='8')) {
            _this.filters.tags.step.push(element.value);    
          }
        });
      }
      axios.post('project/get_projects', this.filters).then(response => {
        let data = response.data;
        if (data.success) {
          _this.resetMap();
          if(!_this.firstLoad){_this.loadMap()}
          _this.firstLoad=false;
          _this.data = data.data;
          _this.data = _this.data.filter(item => !!item.data.projectType?item.data.projectType.isPublished:true)
          _this.getConflicts(function(res) {
            if (res) {
              _this.dispatchData();
            } else {
              this.$notify({
                message: _this.$i18n.t('serverReply.errorProject'),
                timeout: 30000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'danger'
              });
            }
          });
        } else {
          if (data.need_auth) {
            this.$router.push({path: '/login'});
          } else {
            this.$notify({
              message: _this.$i18n.t('serverReply.errorProject'),
              timeout: 30000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          }
        }
        _this.loading=false;
      }).catch(e => {
        console.error("err load project::::", e);
      });
    },
    getConflicts(cb) {
      let _this = this;
      this.loading=true;
      //let uuids = Object.keys(_this.projects);
      let uuids=[];
      this.data.forEach(function(element) {
        uuids.push(element.uuid);
      });
      axios.post('project/conflicts', {uuids: uuids}).then(response => {
        let data = response.data;
        if (data.success) {
          _this.loading=false;
          _this.formatConflictData(data.data);
          cb(true);
        } else {
          if (data.need_auth) {
            _this.$router.push({path: '/login'});
          } else {
            _this.$notify({
              message: _this.$i18n.t('serverReply.errorProject'),
              timeout: 30000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
            cb(false);
          }
        }
        _this.loading=false;
      }).catch(e => {
        console.error("err load project::::", e);
      });
    },
    formatConflictData(data) {
      let _this=this;
      data.forEach((element) => {
        if (element.conflict1.step=='entrave') {
          if ( _this.conflicts[element.conflict1.entraveId]) {
            let list =_this.conflicts[element.conflict1.entraveId].inConflict;
            list.push(element.conflict2);
            _this.conflicts[element.conflict1.entraveId].inConflict = list;
          } else {
            _this.conflicts[element.conflict1.entraveId] = {project: element.conflict1, inConflict: [element.conflict2]}; 
          }
        } else if (element.conflict1.step=='closing') {
          if (_this.conflicts[element.conflict1.closingId]) {
            let list = _this.conflicts[element.conflict1.closingId].inConflict;
            list.push(element.conflict2);
            _this.conflicts[element.conflict1.closingId].inConflict = list;
          } else {
            _this.conflicts[element.conflict1.closingId]={project: element.conflict1, inConflict: [element.conflict2]}; 
          }
        } else {
          if (_this.conflicts[element.conflict1.detourId]) {
            let list = _this.conflicts[element.conflict1.detourId].inConflict;
            list.push(element.conflict2);
            _this.conflicts[element.conflict1.detourId].inConflict = list;
          } else {
            _this.conflicts[element.conflict1.detourId]={project: element.conflict1, inConflict: [element.conflict2]};
          }
        }
        if (element.conflict2.step=='entrave') {
          if (_this.conflicts[element.conflict2.entraveId]) {
            let list = _this.conflicts[element.conflict2.entraveId].inConflict;
            list.push(element.conflict1);
            _this.conflicts[element.conflict2.entraveId].inConflict=list;
          } else {
            _this.conflicts[element.conflict2.entraveId]={project: element.conflict2, inConflict: [element.conflict1]};
          }
        } else if (element.conflict2.step=='closing') {
          if (_this.conflicts[element.conflict2.closingId]) {
            let list = _this.conflicts[element.conflict2.closingId].inConflict;
            list.push(element.conflict1);
            _this.conflicts[element.conflict2.closingId].inConflict=list;
          } else {
            _this.conflicts[element.conflict2.closingId]={project: element.conflict2, inConflict: [element.conflict1]};
          }
        } else {
          if (_this.conflicts[element.conflict2.detourId]) {
            let list = _this.conflicts[element.conflict2.detourId].inConflict;
            list.push(element.conflict1);
            _this.conflicts[element.conflict2.detourId].inConflict=list;
          } else {
            _this.conflicts[element.conflict2.detourId]={project: element.conflict2, inConflict: [element.conflict1]};
          }
        }
      });
    },
    resetMap() {
      if (!this.firstLoad) {
        this.focusUUID=null;
        this.focusProject=null;
        this.focusGraph=[];
        this.focusLayer.removeAll();
      }
      this.data = [];
      this.projects = {};
      this.conflicts = {};
      this.entravesLayer.removeAll();
      this.closingLayer.removeAll();
      this.detoursLayer.removeAll();
      this.pinLayer.removeAll();
      this.conflictsPhasesLayer.removeAll();
      this.conflictsDetourLayer.removeAll();
      this.selectionLayer.removeAll();
      this.animRef.forEach((ref) => {
        clearInterval(ref);
      });
    },
    dispatchData() {
      let _this = this;
      this.data.forEach(function(element) {
        _this.projects[element.uuid] = element.data;
      });
      this.drawProjects();
      try {
      this.setClusters();
      } catch (error) {
        console.log("Error At setClusters!: ", error);
      }
      if (this.focusUUID && this.focusGraph) {
        this.view.goTo(this.focusLayer.graphics.toArray()).then(function(resolved){
          try {
            let entraves = _this.projects[_this.focusUUID].entraves.entraves;
            let selected = entraves[0];
            let midPoint = HelpersMaps.polylineMidPoint(selected, _this.Point, _this.Polyline, _this.geometryEngine, _this.webMercatorUtils);
            _this.view.zoom = 20;
            setTimeout(() => {
              _this.view.popup.open({
                location: [midPoint.longitude, midPoint.latitude],  // location of the click on the view
                ...HelpersDrawProject.populatePopup(_this.projects[_this.focusUUID])
              });
            }, 1500);
          } catch (error) {
            console.log("Error At view.goTo for a focused UUID!: ", error);
          }
        });
      } else {
        this.view.goTo(this.entravesLayer.graphics.toArray()).then(function(resolved){
          if (_this.view.zoom > 13) {_this.view.zoom = 13;}
        });
      }
      /*
      if (this.focusUUID) {
        this.view.goTo(this.focusGraph);
      } else {
        let _this = this;
        this.view.goTo(this.entravesLayer.graphics.toArray()).then(function(resolved){
          if (_this.view.zoom > 13) {_this.view.zoom = 13;}
        });
      }
      */
    },
    getColorByEntity(project, defaultColor) {
      let color;
      let _this=this;
      let len = project.informations.entity.length;
      if (project && project.informations && project.informations.entity) {
        let len = project.informations.entity.length;
        let entity=project.informations.entity[len-1];
        let entities = Tags[this.instance.city].entity.value;
        entities.forEach((element) => {
          if ((element.value==entity) && (element.class)) {
            let classes = Tags[_this.instance.city].entity.classes;
            color = classes[element.class] || defaultColor;
          }
        });
      }
      if (color) {
        return color;
      } else {
        return defaultColor;
      }
    },
    drawProjects() {
      let _this = this;
      let keys = Object.keys(this.projects);
      this.projectsPins = [];
      keys.forEach(function(element, index) {
        try {
        //_this.projects[element].color=_this.colors[index % keys.length];
        let len = _this.colors.length;
        _this.projects[element].color=_this.colors[index % len];
        let color = HelpersMaps.hexToRgb(_this.projects[element].color);
        let focused = (element==_this.focusUUID)?true:false;
        let colorEntraves = _this.getColorByEntity(_this.projects[element], color); 
        _this.drawEntraves(element, _this.projects[element].entraves.entraves, colorEntraves, focused);
        _this.drawPhases(element, _this.projects[element].phases, colorEntraves);
        _this.drawProjectPin(_this.projects[element], colorEntraves, focused);
        _this.drawProjectConflict(_this.projects[element], _this.conflicts, color);
        } catch (error) {
          console.log("Error At drawProjects!: ", error);
        }
      });
    },
    setClusters(){
      if (this.clusterLayer) this.map.remove(this.clusterLayer);
      var defaultSym = new this.SimpleMarkerSymbol({
        size : 14,
        color : [0, 214, 180, 1],
        outline : { width: 2, color: [0, 230, 169, 1] }
        /*size : 0,
        color : [255, 255, 255, 0],
        outline : null*/
      });
      
      var renderer = new this.ClassBreaksRenderer({
        defaultSymbol: defaultSym
      });
      renderer.field = "clusterCount";
      
      var smSymbol = new this.SimpleMarkerSymbol({ size: 30, outline: new this.SimpleLineSymbol({ width: 2, color: [0, 230, 169, 1] }), color: [0, 214, 180, 1] });
      
      /*var mdSymbol = new this.SimpleMarkerSymbol({ size: 24, outline: new this.SimpleLineSymbol({ color: [82, 163, 204, 0.8] }), color: [102, 204, 255, 0.8] });
      var lgSymbol = new this.SimpleMarkerSymbol({ size: 28, outline: new this.SimpleLineSymbol({ color: [41, 163, 41, 0.8] }), color: [51, 204, 51, 0.8] });
      var xlSymbol = new this.SimpleMarkerSymbol({ size: 32, outline: new this.SimpleLineSymbol({ color: [200, 52, 59, 0.8] }), color: [250, 65, 74, 0.8] });*/

      renderer.addClassBreakInfo(0, Infinity, smSymbol);
      /*renderer.addClassBreakInfo(5, 10, mdSymbol);
      renderer.addClassBreakInfo(151, 1000, lgSymbol);
      renderer.addClassBreakInfo(1001, Infinity, xlSymbol);*/

      var areaRenderer;
      // if area display mode is set. Create a renderer to display cluster areas. Use SimpleFillSymbols as the areas are polygons
      var defaultAreaSym = new this.SimpleFillSymbol({
          style: "solid",
          color: [0, 0, 0, 0.2],
          outline: new this.SimpleLineSymbol({ color: [0, 0, 0, 0.3] })
      });
      
      areaRenderer = new this.ClassBreaksRenderer({
          defaultSymbol: defaultAreaSym
      });
      
      areaRenderer.field = "clusterCount";
      var smAreaSymbol = new this.SimpleFillSymbol({ color: [255, 0, 0, 0.5], outline: new this.SimpleLineSymbol({ color: [255, 0, 0, 0.8], style: "dash", width: 0.5 }) });
      var mdAreaSymbol = new this.SimpleFillSymbol({ color: [230, 0, 0, 0.5], outline: new this.SimpleLineSymbol({ color: [230, 0, 0, 0.8], style: "dash", width: 0.5 }) });
      var lgAreaSymbol = new this.SimpleFillSymbol({ color: [168, 0, 0, 0.5], outline: new this.SimpleLineSymbol({ color: [168, 0, 0, 0.8], style: "dash", width: 0.5 }) });
      var xlAreaSymbol = new this.SimpleFillSymbol({ color: [115, 0, 0, 0.5], outline: new this.SimpleLineSymbol({ color: [115, 0, 0, 0.8], style: "dash", width: 0.5 }) });

      areaRenderer.addClassBreakInfo(0, 3, smAreaSymbol);
      areaRenderer.addClassBreakInfo(4, 10, mdAreaSymbol);
      areaRenderer.addClassBreakInfo(11, 15, lgAreaSymbol);
      areaRenderer.addClassBreakInfo(16, Infinity, xlAreaSymbol);
      
      // Set up another class breaks renderer to style the flares individually
      var flareRenderer = new this.ClassBreaksRenderer({
          defaultSymbol: renderer.defaultSymbol
      });
      flareRenderer.field = "clusterCount";

      var smFlareSymbol = new this.SimpleMarkerSymbol({ size: 14, color: [255, 204, 102, 0.8], outline: new this.SimpleLineSymbol({ color: [221, 159, 34, 0.8] }) });
      var mdFlareSymbol = new this.SimpleMarkerSymbol({ size: 14, color: [102, 204, 255, 0.8], outline: new this.SimpleLineSymbol({ color: [82, 163, 204, 0.8] }) });
      var lgFlareSymbol = new this.SimpleMarkerSymbol({ size: 14, color: [51, 204, 51, 0.8], outline: new this.SimpleLineSymbol({ color: [41, 163, 41, 0.8] }) });
      var xlFlareSymbol = new this.SimpleMarkerSymbol({ size: 14, color: [250, 65, 74, 0.8], outline: new this.SimpleLineSymbol({ color: [200, 52, 59, 0.8] }) });
      
      flareRenderer.addClassBreakInfo(0, 19, smFlareSymbol);
      flareRenderer.addClassBreakInfo(20, 150, mdFlareSymbol);
      flareRenderer.addClassBreakInfo(151, 1000, lgFlareSymbol);
      flareRenderer.addClassBreakInfo(1001, Infinity, xlFlareSymbol);
      
      var textSym = new this.TextSymbol({
        color: [255, 255, 255],
        font: {
            size: 16,
            family: "arial"
        },
        yoffset: -6, // setting yoffset as vertical alignment doesn't work in IE/Edge7
        xoffset: 0
      });
      var options = {
        id: "flare-cluster-layer",
        textSymbol : textSym,
        clusterRenderer: renderer,
        areaRenderer: areaRenderer,
        clusterToScale : this.clusterScaleThreshold,
        clusterMinCount : 2,
        //flareRenderer: flareRenderer,
        displayFlares : false,
        //singlePopupTemplate: popupTemplate,
        spatialReference: new this.spatialReference({ "wkid": 4326 }),
        //subTypeFlareProperty: "facilityType",
        //singleFlareTooltipProperty: "name",
        //displaySubTypeFlares: true,
        maxSingleFlareCount: 1,
        clusterRatio: 75,
        clusterAreaDisplay: "always",
        //refreshOnStationary : false,
        data: this.projectsPins
      };
      this.clusterLayer = new this.fcl.FlareClusterLayer(options);
      this.map.add(this.clusterLayer);
      
      /*this.clusterLayer.on("draw-complete", function () {
          console.log('draw complete event callback');
      });*/
    },
    drawEntraves(pUUID, entraves, color, focused) {
      let _this = this;
      entraves.forEach(function(entrave) {
        if (entrave.type=='linear') {
          let obj = {pUUID: pUUID, elementId: entrave.entraveId, type: 'linear', entry: 'entrave', color: color};
          _this.drawEntravePolyline(entrave, obj, 'entrave', color, focused);
        } else {
          let obj = {pUUID: pUUID, elementId: entrave.entraveId, type: 'ponctuel', entry: 'entrave', color: color};
          _this.drawPoint(entrave.selected.path[0], obj, 'entrave', color, focused);
        }
      });
    },
    drawPhases(pUUID, phases, color) {
      let _this = this;
      phases.forEach(function(closing,phaseIndex) {
        closing.closing.forEach(function(close,closeIndex) {
          if (close.type=='linear') {
            //_this.drawPolyline(close.selected.path, obj, 'closing', color);
            let pinCloseGraph = _this.drawPinLinearClose(close, color, phaseIndex, closeIndex);
            let obj = {pUUID: pUUID, elementId: close.closingId, type: 'linear', entry: 'closing', pinCloseGraph: pinCloseGraph, color: color};
            _this.drawClosingPolyline(close, obj, 'closing', color);
          } else {
            let typeClosing = close.dates[0].closingType[0] || 'complete';
            let obj = {pUUID: pUUID, elementId: close.closingId, type: 'ponctuel', entry: 'closing', typeClosing: typeClosing, color: color};
            _this.drawPoint(close.selected.path[0], obj, 'closing', color);
          }
          _this.drawDetours(pUUID, close, color, phaseIndex, closeIndex);
        });
      });
    },
    drawDetours(pUUID, close, color, phaseIndex, closeIndex) {
      let _this = this;
      close.detours.forEach(function(detour,detourIndex) {
        let obj = {pUUID: pUUID, elementId: detour.detourId, type: 'linear', entry: 'detour', color: color,  phaseIndex:phaseIndex+1, closeIndex:closeIndex+1, detourIndex:detourIndex+1};
        _this.drawDetourPolyline(detour, obj, 'detour', color);
      });
    },
    drawProjectPin(project, color, focused) {
      let graph = HelpersDrawProject.drawPin(project, this.Point, this.Polyline, this.Graphic, this.geometryEngine, this.webMercatorUtils, color,false,Tags[this.instance.city]);
      let geographic = this.webMercatorUtils.webMercatorToGeographic(graph.geometry);
      let converted = turf.point([geographic.longitude, geographic.latitude]);
      this.projectsPins.push({"x" : converted.geometry.coordinates[0], "y" : converted.geometry.coordinates[1]});
      this.pinLayer.add(graph);
      if (focused) {
        this.focusLayer.removeAll();
        this.focusLayer.add(graph);
        this.focusGraph = [graph];
      }
    },
    drawProjectConflict(project, conflicts, color) {
      let graphs = HelpersDrawProject.drawConflict(project, conflicts, this.Point, this.Polyline, this.Graphic, this.geometryEngine, this.webMercatorUtils, color);
      let _this=this;
      graphs.phases.forEach((phase) => {
        _this.conflictsPhasesLayer.add(phase);
      });
      graphs.detours.forEach((detour) => {
        _this.conflictsDetourLayer.add(detour);
      });
    },
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    populatePopup(close, phaseIndex, closeIndex) {
      let content = "";
      close.dates.forEach((element,index) => {
        content += '<strong>' + this.$i18n.t('projectForm.closeDate') +'#' + (index+1) + ': </strong>';
        let sdate = this.formatDate(element.sdate);
        let edate = this.formatDate(element.edate);
        let closingType = element.closingType[0];
        content += '<span>' + sdate + '</span> - <span>' + edate +'</span><br/>';
        content += '<strong>' + this.$i18n.t('projectForm.labelClosingType') +'#' + (index+1) + ': </strong>';
        content += '<span>' + closingType + '</span> <br/><br/>';
      });
      content += '<strong>' + this.$i18n.t('projectForm.street') + ': </strong>' + close.value + '<br/>'
      let popupTemplate = {
        title: (this.$i18n.t('mapPage.phase') + phaseIndex) + ' ' + (this.$i18n.t('mapPage.closing') + closeIndex),
        content: content
      };
      return popupTemplate;
    },
    drawPinLinearClose(close, color,phaseIndex="",closeIndex="") {
      let typeClosing = close.dates[0].closingType[0] || 'complete';
      let desc = `<b>Closure # ${closeIndex+1} : </b> </br>`;
      close.dates.forEach((date,index)=>{
        desc += `--------- </br>
                 <b>Period : ${index+1} </b> ${moment((date.sdate)).format('DD-MM-YYYY')} - ${moment((date.edate)).format('DD-MM-YYYY')} </br>
                 <b>Type of Closure : </b> ${date.closingType[0]} </br>
                 <b>Closure's side : </b> ${date.closingSide[0]} </br>
                 <b>Type of street's side : </b> ${date.typeStreet[0]} </br>
                 <b>Comments : </b> ${date.comment} </br>
                `
      })
      let midPoint = HelpersMaps.polylineMidPoint(close, this.Point, this.Polyline, this.geometryEngine, this.webMercatorUtils);
      let symbol = HelpersDrawProject.pointClosingTypeMarker(typeClosing, color);
      const popupTemplateTest = {
          title: `<b>Phase ${phaseIndex+1} : </b> ${close.label}`,
          content: desc
      }
      let popupTemplate = this.populatePopup(close, phaseIndex+1, closeIndex+1);
      let graph = new this.Graphic({
        geometry   : midPoint,
        visible    : true,
        symbol: symbol,
        popupTemplate: popupTemplate
      });
      return graph;
    },
    drawPolyline(paths, obj, type, color, focused) {
      let _this = this;
      var graph;
      paths.forEach(function(path) {
        graph = HelpersDrawProject.drawPolyline(path, _this.Polyline, _this.Graphic, type, color);
        _this.populateGraph(obj, graph);
        if (type=='entrave') {
          _this.entravesLayer.add(graph);
        } else if(type=='closing') {
          _this.closingLayer.add(graph);
        }
        if (focused && (type=='entrave')) {
          //_this.focusGraph = [graph];
        }
      });
    },
    drawEntravePolyline(paths, obj, type, color, focused) {
      let _this = this;
      let polyline = HelpersMaps.updateSimpleLine(paths, this.Point, this.Polyline, this.geometryEngine);
      let graph = new _this.Graphic({
        geometry   : polyline,
        //attributes : paths[0].attributes,
        visible : true,
        symbol : {
          type: 'simple-line',
          style: 'solid',
          color: color,
          width: '2px'
        },
      });
      let graphs = [graph];
      _this.populateGraph(obj, graphs);
      graphs.forEach((g) => {
        _this.entravesLayer.add(g);
      });
      if (focused && (type=='entrave')) {
        //_this.focusGraph = [graph];
      }
    },

    populatePopupDetour(detour, phaseIndex, closeIndex,detourIndex) {
      let content = "";
      detour.dates.forEach((element,index) => {
        content += '<strong>' + this.$i18n.t('projectForm.labelProjectPeriod') +'#' + (index+1) + ': </strong>';
        let sdate = this.formatDate(element.sdate);
        let edate = this.formatDate(element.edate);
        content += '<span>' + sdate + '</span> - <span>' + edate +'</span><br/>';
      });

      let popupTemplate = {
        title: (this.$i18n.t('mapPage.phase') + phaseIndex) + ' ' + (this.$i18n.t('mapPage.closing') + closeIndex + ' ' + (this.$i18n.t('mapPage.detours') + detourIndex)),
        content: content
      };
      return popupTemplate;
    },


    drawDetourPolyline(paths, obj, type, color) {
      let _this = this;
      let detourSensValue = paths.dates[0].detourSensValue;
      let polyline = HelpersMaps.updateSimpleLine(paths, this.Point, this.Polyline, this.geometryEngine);
      let graphs = HelpersMaps.drawRepeated(polyline, color, type, this.Polyline, this.Point, this.Graphic, this.geometryEngine, this.webMercatorUtils, this.geodesicUtils, detourSensValue);
      _this.populateGraph(obj, graphs);
      graphs.forEach((g) => {
        if(obj.phaseIndex){
          let popupTemplate = this.populatePopupDetour(paths, obj.phaseIndex, obj.closeIndex, obj.detourIndex);
          g.popupTemplate = popupTemplate;
        }
        _this.detoursLayer.add(g);
      });
    },
    drawClosingPolyline(paths, obj, type, color) {
      let _this = this;
      let polyline = HelpersMaps.updateSimpleLine(paths, this.Point, this.Polyline, this.geometryEngine);
      let graphs = HelpersMaps.drawRepeated(polyline, color, type, this.Polyline, this.Point, this.Graphic, this.geometryEngine, this.webMercatorUtils, this.geodesicUtils);
      _this.populateGraph(obj, graphs);
      graphs.forEach((g) => {
        _this.closingLayer.add(g);
      });
      _this.closingLayer.add(obj.pinCloseGraph);
    },
    drawPoint(point, obj, type, color, focused) {
      let graph;
      if (type=='closing') {
        graph = HelpersDrawProject.drawPoint(point, this.Graphic, type, color, this.Point, obj.typeClosing);
      } else {
        graph = HelpersDrawProject.drawPoint(point, this.Graphic, type, color, this.Point);
      }
      this.populateGraph(obj, [graph]);
      if (type=='entrave') {
        this.entravesLayer.add(graph);
      } else if(type=='closing') {
        this.closingLayer.add(graph);
      } else {
        this.detoursLayer.add(graph);
      }
      if (focused && (type=='entrave')) {
        //this.focusGraph = [graph];
      }
    },
    populateGraph(obj, graph) {
      if (!this.graphics[obj.pUUID]) {
        this.graphics[obj.pUUID]={};
      }
      obj.graphic=graph;
      obj.ref=null;
      this.graphics[obj.pUUID][obj.elementId]=obj;
    },
    addSearch(Search,Locator) {
      let search = new Search({
        view : this.view,
        autoSelect : true,
        label : "Some Label",
        locationEnabled : false,
        minSuggestCharacters : 3,
        popupEnabled : false,
        searchAllEnabled : true,
        declaredClass: 'hello',
        includeDefaultSources : (!!this.features.searchSource && this.features.searchSource.isDefaultESRI) || false,
        resultGraphicEnabled : true,
        sources : [],
        resultSymbol :{
          type: "picture-marker",
          url: window.location.protocol + '//'+ window.location.host +  "/static/img/icons/pin.png",
          size: 24,
          width: 24,
          height: 24,
          xoffset: 0,
          yoffset: 0
        }
      });

      
      if(!!this.features.searchSource && this.features.searchSource.isQuebec){
        search.sources.push(
          {
              locator : new Locator({
                url: "https://servicescarto.mern.gouv.qc.ca/pes/rest/services/Territoire/AdressesQuebec_Geocodage/GeocodeServer"
              }),
              singleLineFieldName: "SingleLine",
              name: "Adresses Quebec Geocodage",
              placeholder: this.$i18n.t('mapPage.serchePlaceHolder'),
              maxResults: 50,
              maxSuggestions: 6,
              outFields: ["*"],
              resultGraphicEnabled: true,
              resultSymbol :{
                type: "picture-marker",
                url: window.location.protocol + '//'+ window.location.host +  "/static/img/icons/pin.png",
                size: 24,
                width: 24,
                height: 24,
                xoffset: 0,
                yoffset: 0
              }
            }
        )
      }

      if(!!this.features.searchSource && this.features.searchSource.isDefaultCenterline){
        search.sources.push(
          {
            layer : this.layerStreets,
            searchFields : (RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].searchFields:((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].searchFields:RoadNetwork[this.instance.city].searchFields),
            displayField : (RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].displayField:((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].displayField:RoadNetwork[this.instance.city].displayField),
            exactMatch   : false,
            prefix: "",
            suffix: "",
            maxResults: 50,
            maxSuggestions: 6,
            name         : "Rue",
            outFields    : ["*"],
            placeholder  : this.$i18n.t('mapPage.serchePlaceHolder'),
            resultGraphicEnabled : false
          }
        )
      }

      this.view.ui.add(search, "top-right");
    },
    drawBusLines(params) {
      let filterLine = RoadNetwork[this.instance.city].bus.filterLine;
      let _this=this;
      let lines = params[0];
      let filter;
      if (this.extraLayerDraw) this.extraLayerDraw.removeAll(); 
      if (lines.length>0) {
        this.loading=true;
        if (lines.includes('Tout')) {
          filter = "1=1";
        } else {
          filter = filterLine + " in (" + lines.map(function(e){ let e1 = e.replace(/'/g, "''"); return "'" + e1 + "'";}).join() + ')';
        }
        if (this.extraLayerDraw) {
          this.extraLayerDraw.removeAll(); 
        }
        var query = this.layerBus.createQuery();
        query.returnGeometry = true;
        query.outFields = ['*'];
        query.where = filter;
        let colors = ["#c500ff", "#8400a8", "#474747", "#002673", "#005ce6", "#00c5ff", "#55ff00", "#267300", "#737300", "#e6e600", "#ffff73", "#ffaa00", "#a87000", "#730000", "#e60000"], i = 0;
        var groupLines = {};
        this.layerBus.queryFeatures(query).then(function(result){
          result.features.forEach(function(object){
            let line = object.attributes[filterLine];
            if (!groupLines[line]) {
              groupLines[line] = colors[i];
              i = (i + 1) % colors.length;
            } 
            var g = new _this.Graphic({
              geometry   : object.geometry,
              attributes : object.attributes,
              symbol : {
                type  : "simple-line",
                color : groupLines[line],
                width : "3px",
              }
            });
            _this.extraLayerDraw.add(g);
          });
          _this.loading=false;
        }); 
      }
    },
    viewArmZoomEvent() {
      this.view.watch("scale", this.viewZoomFun);
    },
    viewZoomFun(newVal, oldVal, name, target){
      if(oldVal !== newVal){
        if(newVal >= this.clusterScaleThreshold){
          if(this.clusterLayer){
            this.pinLayer.visible = false;
            this.clusterLayer.visible = true;
          }
        }else{
          this.pinLayer.visible = this.display.pin;
          if(this.clusterLayer) {this.clusterLayer.visible = false;}
        }
      }
    },

    openTimeline(){
      if(this.timelineSize == 0){
        this.timelineSize = (screen.height)*0.5;
      }else{
        this.timelineSize = 0;
      }
    },

    getGeoLocation() {
      let _this=this;
      if(!("geolocation" in navigator)) {
        _this.$notify({
          message: "A Geolocation request can't be fulfilled",
          timeout:   10000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
        return;
      }
      navigator.geolocation.getCurrentPosition((position) => {
        this.user.location.longitude = position.coords.longitude;
        this.user.location.latitude = position.coords.latitude;
        this.mapZoom = 15;
        this.loadMap();
      }, (error) => {
        _this.$notify({
          message: error.message,
          timeout:   10000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      });
    }
  }
};
</script>
<style>
  div.map-page-container div.pane-rs.root.rows div.Pane.row:nth-child(2) {
    height: 0px !important;
  }
  div.map-page-container div.full-screen-map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  div.map-page-container  #map {
    height: 100%;
    position: relative;
  }
  body div.map-page-container div.projects-network-container {
    position: absolute;
    top: 45%;
    right: 0;
    width: 50px;
  }
  
  body div.map-page-container div.projects-filters-container {
    position: absolute;
    bottom: 16px;
    left: 13%;
    width: 84%;
  }
  body.sidebar-mini div.map-page-container div.projects-filters-container {
    position: absolute;
    bottom: 16px;
    left: 7%;
    width: 90%;
  }
  
  .Pane.row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  div.Pane.column:last-child {}
  div.map-page-container{
    position: absolute;
    left: 80px;
    right: 0;
    bottom: 0;
  }
  div.map-page-container div.pane-rs.root.rows {
    cursor: unset;
    position: fixed;
    left: 0;
    right: 0;
    top: 0px;
    bottom: 0;
    padding-top: 80px;
  }
  
  div.map-page-container div.create-project {
    position: absolute;
    top: 0px;
    right: 12px;
    z-index: 999;
  }

  div.map-page-container div.get-geo-location {
    position: absolute;
    bottom: 100px;
    right: 12px;
    z-index: 999;
    border-radius: 50%;
    box-shadow: 0px 0px 1px 1px #0000001a;
    animation: pulse-animation 2s infinite;
  }

  div.map-page-container div.get-geo-location > button {
    border-radius: 50%;
  }

  div.map-page-container .esri-popup__header-title {
    color: black;
    font-weight: bold;
  }
  div.projects-details-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    overflow: auto;
  }
  div.color-modal div.modal-dialog {
    width: 40% !important;
    margin: auto;
  }
  div.color-modal div.modal-dialog div.modal-body {
    padding-top: 10px;
    padding-bottom: 10px;
    margin: auto;
  }
  
  div.map-page-container div.esri-ui-top-right {
    top: -11px;
    right: 100px;
  }
  div.map-page-container  div.esri-component.esri-search.esri-widget {
    width: 280px;
    height: 37px;
    border-radius: 5px;
  }
  div.loading-map {
    position: absolute;
    z-index: 9999;
    /*background-color:rgba(0,0,0,0.5);*/
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  div.loading-map img {
    position: absolute;
    top: 48%;
    left: 50%;
    margin-left: 20px;
  }
  span.conflict-title {
    font-weight: bold;
  }
  .esri-feature__content-element:last-child {
    margin-top: 10px;
  }
  .esri-widget__table tr th.esri-feature__field-header {
    width: 20% !important;
  }
  .esri-widget__table tr th.esri-feature__field-data {
    width: 40% !important;
  }
  
  /* Set up some css rules to animate things */
  /* Some rules to change the appearance of clusters and it's text when activated */
  /* Scale up the clusters when activated */
  .cluster-group.activated {
      transform-origin: center;
      transform: scale(1.2);
      transition: transform linear 0.4s;
  }
      /* Change the appearance of clusters when activated */
      .cluster-group.activated .cluster {
          stroke: rgba(255,255,255,1);
          stroke-width: 2;
          transition: all ease 1s;
      }
      .cluster-group.activated .cluster-text {
          fill: #000;
          font-weight: bold;
          transition: all ease 1s;
      }
  /* hide flares by default */
  .flare-group {
      opacity: 0;
  }
      /* animate display of flares */
      .flare-group.activated {
          opacity: 1;
          transition: opacity linear 0.06s;
      }
          /* this just chains the display of flares to occur one after the other using transition delay - could be a better way to do this natively but using SASS or LESS this would be much more concise */
          .flare-group.activated:nth-of-type(1) {
              transition-delay: 0.06s;
          }
          .flare-group.activated:nth-of-type(2) {
              transition-delay: 0.12s;
          }
          .flare-group.activated:nth-of-type(3) {
              transition-delay: 0.18s;
          }
          .flare-group.activated:nth-of-type(4) {
              transition-delay: 0.24s;
          }
          .flare-group.activated:nth-of-type(5) {
              transition-delay: 0.30s;
          }
          .flare-group.activated:nth-of-type(6) {
              transition-delay: 0.36s;
          }
  .cluster-group .flare-group.activated:nth-of-type(7) {
      transition-delay: 0.42s;
  }
  .flare-group.activated:nth-of-type(8) {
      transition-delay: 0.48s;
  }
  div.timeline-container button.settings-icon {
    position: absolute !important;
    left: 50%;
    font-size: 14px;
    margin-left: -10px;
    height: 100%;
    bottom: 0px;
    background-color: white;
    color: #999;
    padding-top: 1px;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 2px;
    border: 1px solid #dcdfe6;
    border-radius: 5px;
    z-index: 999;
    height: 24px;
  }
  div.timeline-container button.settings-icon.active {
    font-size: 12px;
    top: 13px;
  }
  div.Pane.row:last-child {
    min-height: 24px !important;
  }
  div.esri-popup--is-docked-bottom-right{
    margin-bottom: 7rem;
  }
  div#legend-container {
    position: absolute;
    bottom: 15%;
    right: 15px;
  }
  div.esri-legend__layer-body {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  @-webkit-keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }
</style>
